import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';
import { Indent } from 'core/components/Wrappers';
import EmptyWrapper from 'core/components/EmptyWrapper';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';
import CoreGameCompareSkeleton from 'core/components/GameCompare/Skeleton';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import FullWidth from 'core/components/FullWidth';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


function GameCompareSkeleton({ isMobile }) {
  const Wrapper = isMobile ? 'div' : FullWidth;
  const WidthWrapper = isMobile ? EmptyWrapper : MaxWidthWrapper;

  return (
    <Wrapper className={styles.lcpFixer}>
      <style jsx>{`
        .${styles.lcpFixer}
          :global(.mobile) &
            background 50% 0 / 100% auto no-repeat url(/images/waves/waves-bw.webp)
            margin-left ${-SIDE_INDENT_MOBILE}px
            margin-right ${-SIDE_INDENT_MOBILE}px
            padding-left ${SIDE_INDENT_MOBILE}px
            padding-right ${SIDE_INDENT_MOBILE}px
          :global(.desktop) &
            &:after
              background 50% 0 / 100% auto no-repeat url(/images/waves/waves-bw.webp)
      `}</style>
      <WidthWrapper>
        <Indent top={20} />
        <TopicToplineSkeleton height={isMobile ? 22 : 26} />
        <Indent top={10} />
        <TopicHeaderTextsSkeleton
          titleHeight={isMobile ? 50 : 80}
          leadHeight={isMobile ? 100 : 150}
        />
        <MaxWidthWrapper>
          <Indent top={20} />
          <CoreGameCompareSkeleton />
        </MaxWidthWrapper>
      </WidthWrapper>
    </Wrapper>
  );
}

GameCompareSkeleton.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(GameCompareSkeleton);
