import cx from 'classnames';
import PropTypes from 'prop-types';

import withoutSSR from 'core/components/withoutSSR';
import withBreakpoint from 'core/hocs/withBreakpoint';

import { isStarTopic } from 'site/utils';

import getMusicEmbedGradientType from './utils';

import styles from './index.styl';

function EmbedGradient({ block, topic }) {
  const {
    topic_type: topicType,
    is_has_contents: isHasContents,
  } = topic.attributes;

  const {
    id,
    attributes: {
      html,
    },
  } = block;

  /**
   * Только если на странице звезды есть боковая навигация, добавляем модификатор
   */
  const shiftOnStarPage = isStarTopic(topicType) && isHasContents;
  const gradientType = getMusicEmbedGradientType(html, id);

  return (
    <div className={cx(styles.embedGradient, shiftOnStarPage && styles._shiftOnStarPage)}>
      <div className={cx(styles.gradientBackground, styles['_gradientBackground_' + gradientType])} />
    </div>
  );
}

EmbedGradient.propTypes = {
  block: PropTypes.object,
  topic: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(withoutSSR(EmbedGradient));
