import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withBreakpoint from 'core/hocs/withBreakpoint';
import withBindProps from 'core/hocs/withBindProps';

import Gallery from 'core/components/Gallery';
import GalleryFeed from 'site/components/GalleryFeed';
import { Indent } from 'site/components/Wrappers';

import { SIDE_INDENT } from 'site/constants';

function TopicCoverGallery({ content, isDesktop }) {
  const {
    photo_gallery: {
      data: photoGallery,
    },
  } = content.relationships;

  if (!photoGallery) return null;

  const AdditionalSlide = withBindProps({ excludedId: content.id })(GalleryFeed);

  const indent = isDesktop ? SIDE_INDENT : 0;

  return (
    <Indent left={-indent} right={-indent}>
      <Gallery
        photoGallery={photoGallery}
        additionalSlide={AdditionalSlide}
        disableReloadButton
        infoWithSideIndent={isDesktop}
        hashNavigation
        withSmoothHeightChange
        imageSizeAsContainer
        /**
         * TODO:
         * убрать key, разобраться, почему при переходе
         * с доп. слайда (между топиками-галереями)
         * не происходит перехода на первый слайд
         */
        key={content.id}
        arrowsShift={-35}
      />
    </Indent>
  );
}

TopicCoverGallery.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(TopicCoverGallery);
