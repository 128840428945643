import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';
import { menuQuery } from 'core/queries/menu';
import { bebop as bebopApi } from 'core/api/definitions/bebop';

import withBreakpoint from 'core/hocs/withBreakpoint';
import { withRouter } from 'core/libs/router';

import {
  denormalizeData,
  filterRequiredParams,
} from 'core/utils/api';

import CardMain from 'site/cards/CardMain';
import CardSmall from 'site/cards/CardSmall';

import ShapkaDesktop from './Desktop';
import ShapkaMobile from './Mobile';
import getGeo from './utils';

const NEMUZYKA_SLUG = 'nemuzyka';

function SiteShapka(props) {
  const {
    isMobile,
    isDesktop,
    history,
    location,
  } = props;

  const isMainPage = location.pathname === '/';

  const [
    { data: topTopic },
    { data: mainMenu },
  ] = useRequest({
    queries: [
      {
        ...topicsQuery({
          limit: 1,
          list: 'top-announce',
          fields: filterRequiredParams([CardMain], 'fields'),
          include: filterRequiredParams([CardMain], 'include'),
          history,
        }),
        select: data => {
          const topic = denormalizeData(data)[0];
          if (location.pathname === topic?.attributes?.link) {
            return null;
          }
          return topic || null;
        },
      },
      menuQuery({ menu_slug: 'main' }),
    ],
  });

  const { data: geoTags } = useRequest({
    queryKey: ['bebopApi', 'getTags', {
      type: 'geo',
      sort: 'title',
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    enabled: isDesktop,
    select: data => getGeo(denormalizeData(data)),
  });

  const { data: nemuzykaMenuItems } = useRequest({
    queryKey: ['bebopApi', 'getRubric', {
      rubric_slug: NEMUZYKA_SLUG,
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    enabled: isDesktop,
    select: rubric => rubric?.meta?.ancestry?.[0].children
      ?.filter(({ attributes }) => attributes?.enabled)
      ?.map(({ attributes }) => ({
        content: attributes.title,
        link: '/' + NEMUZYKA_SLUG + '/' + attributes.slug,
        show: true,
      })),
  });

  const { data: genresMenu } = useRequest({
    ...menuQuery({ menu_slug: 'genres' }),
    enabled: isDesktop,
  });

  const { data: news } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      limit: isMobile ? 5 : 6,
      include: 'tags',
      topic_type: 'news',
      fields: filterRequiredParams([CardSmall], 'fields'),
    }, location.pathname],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    enabled: isMainPage,
    select: data => {
      const result = denormalizeData(data);
      const resultHasData = Array.isArray(result) && result.length > 1;
      return resultHasData ? result : null;
    },
  });

  const BreakpointShapka = isMobile ? ShapkaMobile : ShapkaDesktop;

  return (
    <BreakpointShapka
      topTopic={topTopic}
      news={news}
      menu={mainMenu}
      {...isDesktop && {
        tags: geoTags,
        nemuzykaMenuItems,
        genresMenu,
      }}
    />
  );
}

SiteShapka.propTypes = {
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(withRouter(SiteShapka));
