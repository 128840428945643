import PropTypes from 'prop-types';
import withBreakpoint from 'core/hocs/withBreakpoint';
import Indent from './Indent';

import { SIDE_INDENT_MOBILE } from 'site/constants';

function NegativeMobile(props) {
  const {
    children,
    isDesktop,
  } = props;

  if (isDesktop) return children;

  return (
    <Indent left={-SIDE_INDENT_MOBILE} right={-SIDE_INDENT_MOBILE}>
      {children}
    </Indent>
  );
}

NegativeMobile.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(NegativeMobile);
