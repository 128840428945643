import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import Button from 'core/components/Button';

import Select from 'site/components/Select';

import styles from './index.styl';

const tagsMap = {
  geo: {
    defaultValue: {
      label: 'Все страны',
      value: 'all',
    },
  },
  genre: {
    defaultValue: {
      label: 'Все жанры',
      value: 'all',
    },
  },
};

function Selectors(props) {
  const {
    filterValues,
    disabledFilters,
    toggleFilter,
    resetFilters,
    theme: {
      colors: {
        blue60,
        magenta60,
      },
      animations: {
        hover: hoverAnimations,
      },
    },
  } = props;

  return (
    <div className={styles.selectWrapper}>
      <style jsx>{`
        .${styles.buttonContent}
          transition color ${hoverAnimations}
          color ${blue60}
          &:hover
            color ${magenta60}
      `}</style>
      <div className={styles.selectors}>
        {Object.keys(tagsMap).map((tag, i) => {
          if (disabledFilters.includes(tag) || !props[tag]) return null;

          const {
            [tag]: tagValue,
          } = filterValues;

          const {
            defaultValue,
          } = tagsMap[tag];

          const options = [defaultValue].concat(props[tag].map((el) => ({
            label: el.attributes.title,
            value: el.attributes.slug,
          })));

          return (
            <Select
              key={i}
              name={tag}
              className={styles.searchSelect}
              options={options}
              value={options.find((el) => el.value === decodeURIComponent(tagValue)) || defaultValue}
              onChange={(el) => toggleFilter(tag, el.value)}
              isDisabled={options.length < 2}
              isSearchable
            />
          );
        })}
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={resetFilters}
          type='ghost'
        >
          <div className={styles.buttonContent}>
            Сбросить фильтры
          </div>
        </Button>
      </div>
    </div>
  );
}

Selectors.propTypes = {
  filterValues: PropTypes.shape({
    geo: PropTypes.string,
    genre: PropTypes.string,
  }),
  disabledFilters: PropTypes.array,
  genre: PropTypes.array,
  geo: PropTypes.array,
  toggleFilter: PropTypes.func,
  resetFilters: PropTypes.func,
  location: PropTypes.object,
  theme: PropTypes.object,
};

Selectors.defaultProps = {
  disabledFilters: [],
};

export default withTheme(Selectors);
