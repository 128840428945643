import { useState } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'core/components/Gallery';
import withBreakpoint from 'core/hocs/withBreakpoint';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';


/**
 * Формируем данные для отображения топиков в галерее,
 * заодно отфильтровывая топики, в которых нет необходимых данных.
 */
function createTopicsImages(result, topic) {
  if (!topic
    || !topic.relationships
    || !topic.relationships.image
    || !topic.relationships.image.data
    || !topic.relationships.photo_gallery
    || !topic.relationships.photo_gallery.data
  ) {
    console.warn(`[Gallery] Topic with id ${topic && topic.id} was dropped, please check its relationships`);
    return result;
  }

  topic.relationships.image.data.topicUrl = topic.attributes.link;
  // Сохраняем количество картинок в галерее топика. Отобраджается на главной странице
  topic.relationships.image.data.topicGalleryCount = topic.relationships.photo_gallery.data.attributes.image_count;
  topic.relationships.image.data.attributes.caption = topic.attributes.headline;
  // Убираем credits, потому-что в галерее галерей он не показывается
  delete topic.relationships.image.data.attributes.credits;
  result.push(topic.relationships.image.data);

  return result;
}

function GalleryTopics({ topics, isMobile }) {
  const [photoGallery] = useState(() => {
    const images = topics.reduce(createTopicsImages, []);
    return {
      attributes: {
        images,
        image_count: images.length,
      },
      type: 'galleryOfTopics',
      id: 'galleryOfTopics',
    };
  });

  return (
    <Gallery
      photoGallery={photoGallery}
      imageVersionName={isMobile ? 'main_gallery' : 'original'}
      disableReloadButton
      isInfinite
    />
  );
}

GalleryTopics.propTypes = {
  isMobile: PropTypes.bool,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
};

export default withBreakpoint(GalleryTopics);
