import PropTypes from 'prop-types';

import H1 from 'core/components/H1';
import Social from 'core/components/Social';
import Divider from 'core/components/Divider';

import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';
import withTheme from 'core/hocs/withTheme';
import Link from 'site/components/Link';
import skip from 'core/resolver/skip';

import styles from './index.styl';

const requiredPayloadFields = [
  'name',
  'slug',
  'job_title',
];


function AuthorPageCard(props) {
  const {
    content,
    author,
    theme: {
      colors: {
        gray40,
        blue60,
      },
    },
    allAuthorsLink,
  } = props;

  if (!(content || author)) return null;

  const {
    attributes: {
      name,
      job_title: jobTitle,
      biography,
      social_networks: socialNetworks,
    },
  } = content || author;


  return (
    <div className={styles.authorCard}>
      <style jsx>{`
        .${styles.job}
          color ${gray40}

        .${styles.allAuthorsLink}
          color ${blue60}
        `}</style>

      <div className={styles.wrapper}>
        <H1 className={styles.name}>{name}</H1>

        {allAuthorsLink && (
          <Link to={allAuthorsLink}>
            <span className={styles.allAuthorsLink}>Все авторы</span>
          </Link>
        )}
      </div>

      {!!jobTitle && <div className={styles.job}>{jobTitle}</div>}

      {!!biography && <div>{biography}</div>}

      <div className={styles.socialNetworks}>
        <Social socials={socialNetworks} />
      </div>

      <Divider />
    </div>
  );
}

AuthorPageCard.propTypes = {
  /** Данные автора, соответствующие модели `person` */
  content: modelPropTypes(personAttributes),
  /** Данные автора, соответствующие модели `person` */
  author: modelPropTypes(personAttributes),
  /** @ignore */
  theme: PropTypes.object,
  /** Ссылка на список всех авторов */
  allAuthorsLink: PropTypes.string,
};


AuthorPageCard.requiredPayloadFields = requiredPayloadFields;

const CardWithHOCs = skip(withTheme(AuthorPageCard));
CardWithHOCs.displayName = 'AuthorPageCard';

export { AuthorPageCard as StorybookComponent };

export default CardWithHOCs;
