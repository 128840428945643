import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import { topicDateFormat } from 'core/utils/dates';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
  'published_at',
];

const requiredPayloadImports = [
  'content',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  content: {},
});


function CompareCard(props) {
  const {
    content,
    theme: {
      fonts,
      colors,
    },
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
    published_at: publishedAt,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  const {
    first_announce: firstAnnounce,
  } = widgets[0]?.attributes || {};

  return (
    <div className={styles.card} >
      <style jsx>{`
        .${styles.card}
          background-color ${colors.layout}
          border 1px solid ${colors.divider}

        .headline
          font 21px/25px ${fonts.display}
          color ${colors.primary}
          .${styles.card}:hover &
            color ${colors.active1}

        .${styles.date}
          font 11px/14px ${fonts.text}
          color ${colors.credits}

        .${styles.firstAnnounce}
          font 16px/20px ${fonts.text}
          color ${colors.caption}
      `}</style>
      <Link
        className={styles.wrapper}
        to={link}
        type='secondary'
      >
        <Lightning
          imageMaxWidth={isMobile ? 550 : 360}
          height={215}
          widget={widgets[0]}
          progressType={4}
          lightningColor={colors.primary}
          borderRadius='0px'
        />
        <div className={styles.body}>
          {publishedAt && (
            <time className={styles.date}>
              {topicDateFormat(publishedAt)}
            </time>
          )}
          {(listHeadline || headline) && (
            <div className='headline'>
              {listHeadline || headline}
            </div>
          )}
          {isMobile && firstAnnounce && (
            <div className={styles.firstAnnounce}>{firstAnnounce}</div>
          )}
        </div>
      </Link>
    </div>
  );
}

CompareCard.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes`и */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = withTheme(withBreakpoint(CompareCard));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { CompareCard as StorybookComponent };

export default Card;
