import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';

import NavigationItems from './NavigationItems';

import styles from './index.styl';

function Navigation(props) {
  const {
    content,
    activeId,
    navigationOffset,
    navigationRef,
    theme,
  } = props;

  const {
    controls: {
      wikiContent: {
        navigation: {
          margin,
        },
        section: {
          padding,
        },
      },
    },
  } = theme;

  return (
    <div
      className={styles.navItems}
      ref={navigationRef}
    >
      <style jsx>{`
        .${styles.navItems}
          @media (prefers-reduced-motion: no-preference)
            transition margin-top .4s ease-out
          margin-top ${navigationOffset || margin}px
          padding-top ${padding}px
      `}</style>
      <NavigationItems
        content={content}
        activeId={activeId}
      />
    </div>
  );
}

Navigation.propTypes = {
  activeId: PropTypes.string,
  navigationOffset: PropTypes.number,
  navigationRef: PropTypes.object,
  content: PropTypes.array,
  theme: PropTypes.object,
};

export default withTheme(Navigation);
