import { useContext } from 'react';
import PropTypes from 'prop-types';

import TopicContent from 'core/components/TopicContent';
import withBreakpoint from 'core/hocs/withBreakpoint';

import resolveRelationships from 'core/utils/relationships';

import { InPage } from 'site/components/Ad/mobile';
import { MaxWidthWrapper } from 'site/components/Wrappers';
import EmbedContext from 'site/components/Embed';

import QuizWrapper from './QuizWrapper';

import * as siteWidgets from 'site/widgets'; // eslint-disable-line import/no-namespace
import { getWidgetsWithExcludedEmbeds } from 'site/utils';

import StarsWrapper from './StarsWrapper';

const relationships = resolveRelationships(['content', 'background'], {}, {
  background: {
    asset: {
      attributes: {
        versions: {},
      },
    },
  },
});

const typesConfig = {
  artist_person: {
    wrapper: StarsWrapper,
    topicContentProps: {
      disableCoreMarkdown: true,
      disableDefaultNavigation: true,
    },
  },
  artist_group: {
    wrapper: StarsWrapper,
    topicContentProps: {
      disableCoreMarkdown: true,
      disableDefaultNavigation: true,
    },
  },
  quiz: {
    wrapper: QuizWrapper,
    topicContentProps: {},
  },
  default: {
    wrapper: MaxWidthWrapper,
    topicContentProps: {},
  },
};

const blocks = Object.keys(siteWidgets).reduce((result, widgetName) => {
  const blockName = widgetName[0].toLowerCase() + widgetName.slice(1);
  const Widget = siteWidgets[widgetName];

  result[blockName] = (block, parentProps, ctx) => (
    <Widget
      block={block}
      parentProps={parentProps}
      ctx={ctx}
    />
  );

  return result;
}, {});

const mobileAds = [
  InPage,
].map((renderer) => ({
  before: 600,
  after: 100,
  showOnlyOnce: true,
  renderer,
}));


function SiteTopicContent({ content, isMobile }) {
  const embedMode = useContext(EmbedContext);

  if (!content) return null;

  const {
    attributes: {
      is_has_contents: isHasContents,
      topic_type: topicType,
    },
  } = content;

  const {
    content: {
      widgets: contentWidgets,
    },
    background: {
      asset: {
        attributes: {
          versions: {
            original: backgroundImage,
          },
        },
      },
    },
  } = relationships(content);

  const {
    wrapper: Wrapper,
    topicContentProps,
  } = typesConfig[topicType] || typesConfig.default;

  const widgets = embedMode
    ? getWidgetsWithExcludedEmbeds(contentWidgets)
    : contentWidgets;

  const isQuiz = topicType === 'quiz';

  return (
    <Wrapper
      content={widgets}
      isHasContents={isHasContents}
      {...isQuiz && { backgroundImage }}
    >
      <TopicContent
        content={widgets}
        interitemSpacing={isMobile ? 20 : 30}
        blocks={blocks}
        mobileAds={mobileAds}
        disableCoreMarkdown={topicContentProps.disableCoreMarkdown}
        disableDefaultNavigation={topicContentProps.disableDefaultNavigation}
        {...embedMode && { markdownComponents: {
          a({ children }) {
            return children;
          },
        } }}
      />
    </Wrapper>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
  mobileAds: PropTypes.arrayOf(PropTypes.object),
};

export default withBreakpoint(SiteTopicContent);
