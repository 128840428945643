import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import isNotEmptyReactElementContent from 'core/utils/content';

import mapTypes from './types';

function Text(props) {
  const {
    tag: TagName,
    children,
    theme,
    breakpoint,
    componentName,
    className,
    id,
    style,
  } = props;

  if (!isNotEmptyReactElementContent(children)) return null;

  const textAtoms = mapTypes[componentName](theme);
  const breakpointAtoms = textAtoms[breakpoint] || textAtoms;

  const elementProps = {
    className: ['text', className].join(' '),
    id,
    style,
  };

  return (
    <Fragment>
      <style jsx>{`
        .text
          font ${breakpointAtoms.font}
        `}</style>
      <TagName {...elementProps}>{children}</TagName>
    </Fragment>
  );
}

Text.propTypes = {
  /**
   * Имя класса для дополнительной стилизации
   */
  className: PropTypes.string,
  /**
   * Имя компонента текстового блока для подключения стилей из темы
   */
  componentName: PropTypes.string.isRequired,
  /**
   * Тег, в котором будет отрендерен контент
   */
  tag: PropTypes.string,
  /**
   * id элемента
   */
  id: PropTypes.string,
  /** @ignore */
  breakpoint: breakpointPropTypes(),
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  style: PropTypes.object,
};

Text.defaultProps = {
  tag: 'div',
};

const TextWithHOCs = withTheme(withBreakpoint(Text));
TextWithHOCs.displayName = 'Text';

export { Text as StorybookComponent };
export default TextWithHOCs;
