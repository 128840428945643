import PropTypes from 'prop-types';
import cx from 'classnames';

import { Desktop } from 'core/components/breakpoint';
import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';
import EmptyWrapper from 'core/components/EmptyWrapper';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';
import SocializatorSkeleton from 'core/components/Socializator/Skeleton';

import { MaxWidthWrapper, Indent } from 'site/components/Wrappers';
import FullWidth from 'core/components/FullWidth';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


function Common({ isMobile, theme }) {
  const {
    colors: {
      divider: dividerColor,
    },
  } = theme;

  const Wrapper = isMobile ? 'div' : FullWidth;
  const WidthWrapper = isMobile ? EmptyWrapper : MaxWidthWrapper;

  return (
    <Wrapper className={styles.lcpFixer}>
      <style jsx>{`
        .${styles.lcpFixer}
          :global(.mobile) &
            background 50% 0 / 100% auto no-repeat url(/images/waves/waves-bw.webp)
            margin-left ${-SIDE_INDENT_MOBILE}px
            margin-right ${-SIDE_INDENT_MOBILE}px
            padding-left ${SIDE_INDENT_MOBILE}px
            padding-right ${SIDE_INDENT_MOBILE}px
          :global(.desktop) &
            &:after
              background 50% 0 / 100% auto no-repeat url(/images/waves/waves-bw.webp)
        .${styles.socializator}
          border-top 1px solid ${dividerColor}
          border-bottom 1px solid ${dividerColor}
      `}</style>
      <WidthWrapper>
        <TopicToplineSkeleton height={isMobile ? 22 : 26} />
        <Indent top={10} />
        <TopicHeaderTextsSkeleton
          titleHeight={isMobile ? 50 : 80}
          leadHeight={isMobile ? 100 : 150}
        />
        <Desktop>
          <div className={styles.socializator}>
            <div className={cx('lb-skeleton', styles.subscribe)} />
            <SocializatorSkeleton />
          </div>
        </Desktop>
        <Indent
          top={isMobile ? 10 : 24}
          bottom={isMobile ? 24 : 36}
          left={isMobile ? -SIDE_INDENT_MOBILE : -SIDE_INDENT}
          right={isMobile ? -SIDE_INDENT_MOBILE : -SIDE_INDENT}
        >
          <div className={cx('lb-skeleton', styles.cover)} />
        </Indent>
        <div className={cx('lb-skeleton', styles.text)} />
      </WidthWrapper>
    </Wrapper>
  );
}

Common.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(Common));
