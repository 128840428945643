import PropTypes from 'prop-types';

import RecommenderCore from 'core/components/Recommender';

import withBreakpoint from 'core/hocs/withBreakpoint';

import CardVertical from 'site/cards/CardVertical';

import styles from './index.styl';

function Recommender({ isMobile }) {
  return (
    <RecommenderCore
      blockId={isMobile ? 'a758152465d240a59145cc3da95b3b52' : '6bba4cec7d684a93a15bba5ac2b7bd1d'}
      card={CardVertical}
      limit={3}
      interitemSpacing={isMobile ? 10 : 20}
      columns={3}
      itemClassName={styles.wrapper}
      grid
    />
  );
}

Recommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Recommender);
