import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import { buildSearchLink } from 'core/utils/url-helper';

import withTheme from 'core/hocs/withTheme';

import styles from './searchForm.styl';

function SearchForm(props) {
  const {
    innerRef,
    onSubmit,
    history,
    theme: {
      colors,
      icons: {
        search: SearchIcon,
      },
    },
  } = props;

  const handleSubmit = e => {
    e.preventDefault();
    const { value } = e.target.query;
    if (value) history.push(buildSearchLink('', value));
    onSubmit(e);
  };

  return (
    <form
      ref={innerRef}
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <style jsx>{`
        .${styles.input}
          background ${colors.content}
          border 1px solid ${colors.divider}
        .${styles.submit}
          color ${colors.content}
          .${styles.input}:focus+&
            color ${colors.primary}
      `}</style>
      <input
        className={styles.input}
        name='query'
        placeholder='поиск...'
      />
      <button
        className={styles.submit}
        type='submit'
      >
        <SearchIcon
          width={18}
          height={18}
          className={styles.icon}
        />
      </button>
    </form>
  );
}

SearchForm.propTypes = {
  innerRef: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  theme: PropTypes.object,
};

export default withRouter(withTheme(SearchForm));
