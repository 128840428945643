import cx from 'classnames';
import PropTypes from 'prop-types';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import ArrowRight from 'core/components/Icon/ArrowRightSocial';
import Vk from './networks/vkontakte/logo.svg';
import Ok from './networks/odnoklassniki/logo.svg';
import Tg from './networks/telegram/logo.svg';
import Yt from './networks/youtube/logo.svg';

import styles from './index.styl';

const logos = {
  vkontakte: Vk,
  odnoklassniki: Ok,
  telegram: Tg,
  youtube: Yt,
};

function SocialMedia(props, context) {
  const {
    isDesktop,
    theme,
    socialNetwork: {
      message,
      slug,
    },
  } = props;

  const { socials } = context;

  const network = socials.find(val => val.name.toLowerCase() === slug);

  if (!(slug && network)) return null;

  const Icon = logos[slug];

  const iconSize = isDesktop ? 50 : 32;

  return (
    <YandexMetrikaGoalSender
      click={slug + '_clicked'}
      show={slug + '_viewed'}
      disabled={!isDesktop}
    >
      <style jsx>{`
        .${styles.socialMedia}
          font-family ${theme.fonts.text}
        `}</style>
      <a
        href={network.link}
        className={cx(styles.socialMedia, styles[slug])}
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className={styles.logo}>
          <Icon width={iconSize} height={iconSize} />
        </div>
        <div className={styles.title}>{message}</div>
        <span className={styles.subscribe}>
          Подписаться
          <span className={styles.icon}>
            <ArrowRight width={15} height={15} />
          </span>
        </span>
      </a>
    </YandexMetrikaGoalSender>
  );
}


SocialMedia.propTypes = {
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  socialNetwork: PropTypes.object,
};

SocialMedia.contextTypes = {
  socials: PropTypes.array,
};

export default withBreakpoint(withTheme(SocialMedia));
