import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { topicDateFormat } from 'core/utils/dates';

import withTheme from 'core/hocs/withTheme';
import withBindProps from 'core/hocs/withBindProps';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Link from 'site/components/Link';

import cutDown from 'core/utils/cut-down';

import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'published_at',
  'alternative_headline',
];

const requiredPayloadImports = [
  'tags',
  'rubric',
  'search_result',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  search_result: {
    title: '',
    body: '',
  },
});


function CardSmall(props) {
  const {
    content,
    theme: {
      fonts,
      colors,
    },
    size,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: publishedAt,
    alternative_headline: altHeadline,
  } = content.attributes;

  const {
    tags,
    rubric,
    search_result: {
      title: searchTitle,
      body: searchBody,
    },
  } = relationships(content);

  const oneTagAvailable = !!(tags && tags.length > 0 && tags[0]);
  const categoryText = (oneTagAvailable && tags[0].attributes.title)
    || (rubric && (rubric.title || rubric.root_title));

  return (
    <Link
      to={link}
      type='secondary'
      className={styles.link}
    >
      <style jsx>{`
      .${styles.headline}
        font 16px/24px ${fonts.text}
        color ${colors.primary}
        &._size_m
          font 18px/27px ${fonts.text}
        :global(b)
          color ${colors.magenta60}

      .${styles.categoryText}
      .time
        font 11px/14px ${fonts.text}

      .${styles.categoryText}
        color ${colors.gray40}

      .time
        color ${colors.gray60}

      .${styles.altHeadline}
        font 16px/20px ${fonts.text}
        color ${colors.primary}
        :global(b)
          color ${colors.magenta60}
    `}</style>
      {categoryText && <span className={styles.categoryText}>{categoryText}</span>}
      {publishedAt && <span className='time'>{topicDateFormat(publishedAt)}</span>}
      <div
        className={cx(
          styles.headline,
          `_size_${size}`,
          altHeadline && styles._altHeadline
        )}
      >
        <MarkdownWrapper inline>
          {searchTitle || headline}
        </MarkdownWrapper>
      </div>
      <div className={styles.altHeadline}>
        <MarkdownWrapper inline>
          {cutDown(searchBody) || altHeadline}
        </MarkdownWrapper>
      </div>
    </Link>
  );
}

CardSmall.propTypes = {
  /** Размер карточки */
  size: PropTypes.string,
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  theme: PropTypes.object,
};

CardSmall.defaultProps = {
  size: 's',
};

const Card = withTheme(CardSmall);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { Card as CardSmallS };
export const CardSmallM = withBindProps({ size: 'm' })(Card);

export { CardSmall as StorybookComponent };

export default Card;
