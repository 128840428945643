import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getCurrentMenuMatches } from 'core/utils/url-helper';

import withTheme from 'core/hocs/withTheme';
import { withRouter } from 'core/libs/router';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'site/components/Link';

import styles from './index.styl';


const buildNavLink = (slug, link, postfix) => {
  return slug ? `/label/${slug}${postfix || ''}`
    : `${link}${postfix || ''}`;
};

function MenuItems(props) {
  const {
    items,
    postfix,
    theme,
    location,
  } = props;

  const emptyItems = !items || !items.length;

  const currentMatches = useMemo(
    () => {
      if (emptyItems) return null;

      const menuItems = items.reduce((result, item) => {
        const link = buildNavLink(item?.attributes?.slug, item.link, postfix);
        let newItem = [{ ...item, link }];

        if (item.incoming && item.incoming.length > 0) {
          const multilevelItems = item.incoming
            .map(innerItem => ({
              ...innerItem,
              link: buildNavLink(innerItem?.attributes?.slug, '', postfix),
            }));

          newItem = newItem.concat(multilevelItems);
        }

        return result.concat(newItem);
      }, []);

      return getCurrentMenuMatches(menuItems, location);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, items]
  );

  if (emptyItems) return null;

  const shapkaAtoms = theme.controls.shapka;
  const linkBreakpointAtoms = shapkaAtoms.dropdown.link;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          font 16px/24px ${theme.fonts.text}
          padding ${linkBreakpointAtoms.padding}
          text-transform ${linkBreakpointAtoms.textTransform}
          letter-spacing ${linkBreakpointAtoms.letterSpacing}
          color ${linkBreakpointAtoms.idle.color}
          text-decoration ${linkBreakpointAtoms.idle.decoration}
          transition color ${theme.animations.hover}

          &:active
            color ${linkBreakpointAtoms.active.color}
            text-decoration ${linkBreakpointAtoms.active.decoration}
          &:hover
            color ${linkBreakpointAtoms.hover.color}
            text-decoration ${linkBreakpointAtoms.hover.decoration}
        .link._current
          color ${linkBreakpointAtoms.current.color}
          text-decoration ${linkBreakpointAtoms.current.decoration}
      `}</style>
    </scope>
  );

  const currentLink = currentMatches[0]?.link;

  return (
    <ul className={styles.list}>
      {
        items.map((item, index) => {
          const isMultilevel = item.incoming && item.incoming.length > 0;
          const {
            title,
            slug,
          } = item.attributes || {};

          let link = buildNavLink(slug, item.link, postfix);
          let isCurrent = currentLink === link;

          return (
            <li key={index} className={styles.listItem}>
              <Link
                to={link}
                type='blank'
                className={scoped.wrapClassNames('link', isCurrent && '_current', styles.link)}
              >
                {item.content || title}
              </Link>
              {isMultilevel && (
                <ul className={cx(styles.list, styles.incoming)}>
                  {item.incoming.map((incom, i) => {
                    const {
                      title: incomTitle,
                      slug: incomSlug,
                    } = incom.attributes || {};

                    link = buildNavLink(incomSlug, '', postfix);
                    isCurrent = currentLink === link;

                    return (
                      <li key={i + incom.id} className={styles.listItem}>
                        <Link
                          to={link}
                          type='blank'
                          className={scoped.wrapClassNames('link', isCurrent && '_current', styles.link)}
                        >
                          {incomTitle}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })
      }
      <scoped.styles />
    </ul>
  );
}

MenuItems.propTypes = {
  items: PropTypes.array,
  postfix: PropTypes.string,
  theme: PropTypes.object,
  location: PropTypes.object,
};

export default withTheme(withRouter(MenuItems));
