import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/hocs/withTheme';

import Text from './Text';

function ErrorMessage({ theme, className, ...otherProps }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .errorMessage
          color ${theme.colors.error}
      `}</style>
    </scope>
  );

  return (
    <Fragment>
      <Text
        componentName='errorMessage'
        className={[scope.wrapClassNames('errorMessage'), className].join(' ')}
        {...otherProps}
      />
      <scope.styles />
    </Fragment>
  );
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(ErrorMessage);
