import PropTypes from 'prop-types';

import withBreakpoint from 'core/hocs/withBreakpoint';

import Drum from 'core/components/Drum';
import EmptyWrapper from 'core/components/EmptyWrapper';
import Feed from 'core/components/Feed';
import H2 from 'core/components/H2';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { Indent } from 'site/components/Wrappers';

import CardStory, { CardStoryType1 } from 'site/cards/CardStory';
import CardVertical from 'site/cards/CardVertical';

import styles from './index.styl';


function Popular({ content, isMobile }) {
  if (!content || !content.length) return null;

  const Title = () => (
    <div className={styles.title}>
      <H2>Популярное</H2>
    </div>
  );

  if (isMobile) {
    return (
      <EmptyWrapper>
        <Title />
        <Indent top={10}>
          <Drum
            content={content}
            card={CardStoryType1}
            itemWidthMobile={290}
            spaceBetween={10}
          />
        </Indent>
      </EmptyWrapper>
    );
  }

  const first = content.slice(0, 2);
  const second = content.slice(2);

  return (
    <EmptyWrapper>
      <Title />
      {first.length > 0 &&
        <Indent top={20}>
          <Feed
            content={first}
            card={CardStory}
            columns={first.length}
            interitemSpacing={20}
            grid
          />
        </Indent>
      }
      {second.length > 0 &&
        <Indent top={20}>
          <Feed
            content={second}
            card={CardVertical}
            interitemSpacing={20}
            columns={second.length}
            grid
          />
        </Indent>
      }
    </EmptyWrapper>
  );
}

Popular.propTypes = {
  content: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Popular);
