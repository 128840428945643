import PropTypes from 'prop-types';
import withBreakpoint from 'core/hocs/withBreakpoint';
import Indent from './Indent';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

function PageIndent(props) {
  const {
    children,
    isMobile,
  } = props;

  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Indent
      left={horizontal}
      right={horizontal}
      top={vertical}
      bottom={vertical}
    >
      {children}
    </Indent>
  );
}

PageIndent.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(PageIndent);
