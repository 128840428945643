import PropTypes from 'prop-types';

import { Indent } from 'core/components/Wrappers';
import withBindProps from 'core/hocs/withBindProps';
import Gallery from 'core/components/Gallery';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

import GalleryFeed from 'site/components/GalleryFeed';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';


export default function PhotoGallery({ block, parentProps }) {
  const {
    topic: {
      content: {
        id,
      },
    },
    isDesktop,
  } = parentProps;

  const AdditionalSlide = withBindProps({ excludedId: id })(GalleryFeed);

  const spacing = isDesktop ? SIDE_INDENT : SIDE_INDENT_MOBILE;

  return (
    <Indent left={-spacing} right={-spacing} >
      <Gallery
        photoGallery={block}
        additionalSlide={AdditionalSlide}
        disableReloadButton
        withSmoothHeightChange
        infoWithSideIndent={isDesktop}
        arrowsShift={-35}
      />
    </Indent>
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
  parentProps: PropTypes.object,
};
