import PropTypes from 'prop-types';

import skip from 'core/resolver/skip';
import withTheme from 'core/hocs/withTheme';

import modelPropTypes, {
  tagAttributes,
} from 'core/utils/prop-types/model';

import Link from 'site/components/Link';

import styles from './index.styl';


function CardGenre({ content, theme }) {
  if (!content) return null;

  const {
    title,
    slug,
  } = content.attributes;

  return (
    <Link to={`/label/${slug}`} type='secondary'>
      <style jsx>{`
        .${styles.title}
          font 16px/24px ${theme.fonts.display}
          color ${theme.colors.primary}
        `}
      </style>
      <div className={styles.title}>{title}</div>
    </Link>
  );
}

CardGenre.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(tagAttributes),
  /** @ignore */
  theme: PropTypes.object,
};

export { CardGenre as StorybookComponent };

export default skip(withTheme(CardGenre));
