import PropTypes from 'prop-types';
import cx from 'classnames';

import SocializatorCore from 'core/components/Socializator';
import withBreakpoint from 'core/hocs/withBreakpoint';
import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';


function Socializator({ isMobile, theme, disableDividers }) {
  const {
    colors: {
      divider,
      gray40,
    },
    controls: {
      socializator: {
        iconsGap,
      },
    },
  } = theme;

  if (isMobile) return <SocializatorCore />;

  return (
    <div
      className={cx(
        styles.socializatorWrapper,
        disableDividers && styles._disableDividers,
      )}
    >
      <style jsx>{`
        .${styles.socializatorWrapper}
          border-top-color ${divider}
          border-bottom-color ${divider}
          color ${gray40}
        .shareTitle
          margin-right ${iconsGap}px
        `}</style>
      <span className='shareTitle'>Поделиться:</span>
      <SocializatorCore buttons={['vkontakte', 'odnoklassniki', 'whatsapp', 'telegram', 'copy']} />
    </div>
  );
}

Socializator.propTypes = {
  disableDividers: PropTypes.bool,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(Socializator));
