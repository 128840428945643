import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';
import EmptyWrapper from 'core/components/EmptyWrapper';

import styles from './index.styl';

function Error({ theme, isFullscreen }) {
  const {
    fonts: {
      display,
    },
    colors: {
      error2,
      content,
      gray30,
    },
  } = theme;
  return (
    <EmptyWrapper>
      <style jsx>{`
        .${styles.errorText}
          font 400 16px/20px ${display}
          color ${error2}
        .${styles.error}
          background radial-gradient(110px 110px at 50% 50%, ${gray30} 50%, ${content} 90%)
        .${styles.errorIcon}
          background url('/images/404.png') no-repeat
          background-size contain
      `}</style>
      <div className={styles.errorText}>
        К сожалению, не удалось загрузить другие интересные галереи
      </div>
      <div className={styles.errorIconWrapper}>
        <div className={cx(isFullscreen && styles.error)}>
          <div className={styles.errorIcon} />
        </div>
      </div>
    </EmptyWrapper>
  );
}

Error.propTypes = {
  theme: PropTypes.object,
  isFullscreen: PropTypes.bool,
};

export default withTheme(Error);
