import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import EmptyWrapper from 'core/components/EmptyWrapper';
import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import TopicTopline from 'core/components/TopicTopline';
import Position from 'core/components/GridPosition';
import FullWidth from 'core/components/FullWidth';

import withBreakpoint from 'core/hocs/withBreakpoint';
import { withRouter } from 'core/libs/router';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Socializator from 'site/components/Socializator';
import { MaxWidthWrapper, Indent } from 'site/components/Wrappers';

import TopicCover from '../covers';
import TopicHeaderStars from '../TopicHeaderStars';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import book from 'site/images/book.png';

import { isStarTopic } from 'site/utils';

import styles from './index.styl';


function TopicHeader(props) {
  const {
    content,
    galleryFeedContent,
    isMobile,
    isDesktop,
    isGameCompare,
    location,
  } = props;

  const {
    topic_type: topicType,
  } = content.attributes;

  const isQuiz = topicType === 'quiz';
  const isPelevin = location.pathname === '/stati/viktoru-pelevinu-61-prazdnuem-den-rozhdeniya-pisatelya-s-ego-luchshimi-proizvedeniyami.htm';

  const pelevinStyles = isPelevin && resolveScopedStyles(
    <scope>
      <style jsx global>{`
        body
          background-image: url(${book})
        .content-background.customBg
          background linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255, 0.9) 50%,  rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%)
      `}
      </style>
    </scope>
  );


  if (isQuiz) {
    return (
      <Position center={isDesktop}>
        <Indent bottom={isMobile ? 20 : 30}>
          <TopicTopline content={content} hideFontSizeChanger />
        </Indent>
      </Position>
    );
  }

  if (isStarTopic(topicType)) {
    return (
      <Indent bottom={30}>
        <TopicHeaderStars content={content} />
      </Indent>
    );
  }

  const Wrapper = isMobile ? 'div' : FullWidth;
  const DesktopWidthNormalizer = isMobile ? EmptyWrapper : MaxWidthWrapper;

  return (
    <EmptyWrapper>
      <Wrapper className={!isPelevin && styles.lcpFixer}>
        <style jsx>{`
          .${styles.lcpFixer}
            :global(.mobile) &
              background 50% 0 / 100% auto no-repeat url(/images/waves/waves-bw.webp)
              margin-left ${-SIDE_INDENT_MOBILE}px
              margin-right ${-SIDE_INDENT_MOBILE}px
              padding-left ${SIDE_INDENT_MOBILE}px
              padding-right ${SIDE_INDENT_MOBILE}px
            :global(.desktop) &
              &:after
                background 50% 0 / 100% auto no-repeat url(/images/waves/waves-bw.webp)
      `}</style>
        <DesktopWidthNormalizer>
          <TopicTopline
            content={content}
            hideFontSizeChanger
            isShowAuthors
          />
          <Indent bottom={10} />
          <TopicHeaderTexts />
          {!isGameCompare && <Socializator />}
        </DesktopWidthNormalizer>
      </Wrapper>
      <DesktopWidthNormalizer>
        {!isGameCompare &&
          <Indent
            top={isMobile ? 10 : 24}
            bottom={isMobile ? 24 : 36}
            {...isMobile && {
              left: -SIDE_INDENT_MOBILE,
              right: -SIDE_INDENT_MOBILE,
            }}
          >
            <TopicCover galleryFeedContent={galleryFeedContent} content={content} />
          </Indent>
        }
      </DesktopWidthNormalizer>
      {isPelevin && <pelevinStyles.styles />}
    </EmptyWrapper>
  );
}

TopicHeader.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  galleryFeedContent: PropTypes.array,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isGameCompare: PropTypes.bool,
  location: PropTypes.object,
};

export default withBreakpoint(withRouter(TopicHeader));
