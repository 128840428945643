import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Avatar from 'core/components/Avatar';

import styles from './index.styl';

function Author(props) {
  const {
    author,
    additionalClass,
    theme,
  } = props;
  const {
    attributes: {
      avatar,
      name,
      job_title: jobTitle,
    },
  } = author || { attributes: {} };
  const {
    colors: {
      credits,
    },
    fonts: {
      text,
    },
  } = theme;

  return (
    <div className={styles.author}>
      <style jsx>{`
        .${styles.meta}
          font 14px/18px ${text}
          color ${credits}
          :global(.mobile) &
            font 13px/17px ${text}
            @media (max-width: 375px)
              font 400 11px/14px ${text}
      `}</style>
      <style jsx>{`
        .${styles.avatar}
          &:before
            background center url('/images/waves/waves2.webp') no-repeat
            background-size contain
      `}</style>
      <div className={styles.meta}>
        <MarkdownWrapper>
          {`${name}${!!jobTitle && `, ${jobTitle}`}`}
        </MarkdownWrapper>
      </div>
      {avatar && <div className={cx(styles.avatar, additionalClass)}>
        <Avatar
          isCircular
          src={avatar.attributes.versions.original}
          size={80}
        />
      </div>}
    </div>
  );
}

Author.propTypes = {
  author: PropTypes.object,
  additionalClass: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(Author);
